.header {
  background: #2f2f2f;
  color: white;
  margin: 0 auto;
  padding: 12px 0px 12px;

  width: 100%;
  position: fixed;
  z-index: 20;

  @media (min-width: 2400px) {
    padding: 18px 0px 18px;
  }

  @media (max-width: 550px) {
    padding: 10px 0px 0px;
    width: 100%;
  }

  .headerContent {
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1000px) {
      width: 90%;
    }
  }

  .mobileControl {
    display: flex;
    gap: 10px;
    svg {
      margin-left: 5px;
    }
  }
}

.logo {
  height: 60px;
}

p.textFooter {
  position: relative;
  top: -10px;
  left: -3px;
  text-align: right;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
}
