.container {
  margin: 50px 0px 50px;
  display: flex;
  justify-content: space-around;
  font-weight: 500;
  @media (max-width: 550px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (min-width: 2400px) {
    margin-top: 100px;
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & img,
  svg {
    width: 120px;
    height: 120px;

    @media (min-width: 2400px) {
      width: 160px;
      height: 160px;
    }

    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  }
  @media (max-width: 550px) {
    flex-direction: row;
    column-gap: 100px;
  }
  @media (min-width: 2400px) {
    img {
      width: 160px;
      height: 160px;
    }
  }
}

.description {
  margin-top: 15px;
  height: 50px;
  text-align: center;
  width: 130px;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;

  @media (max-width: 768px) {
    width: 80px;
  }
  @media (max-width: 550px) {
    text-align: left;
  }

  @media (min-width: 2400px) {
    width: 220px;
    font-size: 20px;
  }

  &::before {
    content: '';
    display: block;
    width: 65px;
    height: 1px;
    left: 32px;
    top: -10px;
    position: absolute;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.35);

    &body.light {
      background: black;
    }

    @media (min-width: 2400px) {
      width: 120px;
      left: 50px;
    }

    @media (max-width: 768px) {
      width: 40px;
      left: 20px;
    }
    @media (max-width: 550px) {
      content: '';
      display: block;
      width: 1px;
      height: 60px;
      left: -50px;
      position: absolute;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.35);
    }
  }
}

.lightDescription {
  &::before {
    background: #2f2f2f;
  }
}
