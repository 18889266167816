.projectItem {
  margin-top: 80px;
  margin-bottom: 40px;
  @media (max-width: 550px) {
    margin-top: 30px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: start;
    column-gap: 40px;
    width: 100%;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .tourButton {
    border: none;
    background: #00bbe6;
    color: white;
    font-size: 30px;
    padding: 5px 10px;
  }

  .tour {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
  }

  .close {
    z-index: 1000;
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: #2f2f2f;
    cursor: url('../../../assets/image/pointer.svg'), auto;
  }

  h2 {
    text-transform: uppercase;
    font-weight: 500;
    background: #00bbe6;
    padding: 10px;
    margin-bottom: 30px;
    @media (min-width: 1920px) {
      font-size: 25px;
    }
    @media (min-width: 2400px) {
      font-size: 35px;
    }
    @media (max-width: 1100px) {
      font-size: 20px;
    }
    @media (max-width: 550px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .text {
    font-size: 20px;
    line-height: 33px;
    ul {
      padding: 0px 40px;
      margin-bottom: 20px;

      li {
        list-style: disc;
        line-height: 1.5;

        @media (max-width: 1100px) {
          font-size: 18px;
        }
        @media (max-width: 550px) {
          font-size: 14px;
        }
      }
    }
  }

  .slider,
  .text {
    width: 50%;
    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}
