.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 100px;
  gap: 20px;

  .map {
    iframe {
      position: relative;
      width: 760px;
      height: 500px;

      @media (max-width: 1250px) {
        width: 560px;
        height: 400px;
      }
      @media (max-width: 1000px) {
        width: 460px;
        height: 460px;
      }
      @media (max-width: 950px) {
        width: 100%;
        height: 300px;
      }
    }
  }

  @media (max-width: 950px) {
    margin-top: 30px;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  
  .docs {
    background-color: #7f7f7f4c;
    border-radius: 10px;
    padding: 25px 15px 23px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 25px;
    width: 220px;
    & > a {
      font-size: 14px;
      align-self: center;
      font-weight: 400;
    }
    & > a {
      display: flex;
      align-items: center;
    }
    &:not(:last-child){
      margin-bottom: 15px;
    }
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(2, 1fr);
    .docs {
      width: 230px;
    }

  }
}

.contact {
  margin-bottom: 40px;
  
  .withIcons {
    display: flex;
    & > img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
  & > h3 {
    border-radius: 7px;
    padding: 7px 25px 0;
    margin: 0px 0px 20px;
    width: max-content;
    background: #00bce6a4;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .1rem;
    @media (min-width: 1920px) {
      font-size: 25px;
      padding: 5px 25px 0;
    }
  }

  & > p {
    font-size: 20px;
    font-weight: 500;

    :first-child {
      display: block;
      margin-bottom: 10px;
    }
  }

  
}

.containerImage {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.ogrn {
  margin-top: 30px;
  margin-bottom: 45px;
}


.link {
  & img {
    width: 50px;
    height: 50px;

    @media (max-width: 550px) {
      width: 40px;
      height: 40px;
    }
    @media (min-width: 2400px) {
      width: 70px;
      height: 70px;
    }
  }
}

@media (min-width: 2400px) {
  .contact h3 {
    font-size: 42px;
  }
  .contact p {
    font-size: 36px;
  }
}
