.cardCarousel {
  --card-width: 80%;
  --card-max-width: 200px;
  --card-height: 400px;
  --carousel-min-width: 600px;
  z-index: 1;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 400px;
  transition: filter 0.3s ease;
  overflow: visible;
}

.cardCarousel.smooth-return {
  transition: all 0.2s ease;
}

.card {
  background: whitesmoke;
  width: 250px;
  text-align: center;
  height: 400px;
  position: absolute;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.5);
  transition: inherit;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Block {
  pointer-events: none;
}

@media screen and (min-width: 2400px) {
  .cardCarousel {
    min-width: 1600px;
    height: 580px;
  }
  .card {
    width: 330px;
    height: 600px;
  }
}

@media screen and (max-width: 1800px) {
  .cardCarousel {
    min-width: 1800px;
  }
  .card {
    width: 230px;
  }
}

@media screen and (max-width: 1400px) {
  .cardCarousel {
    min-width: 1600px;
    height: 400px;
  }
  .card {
    width: 220px;
    height: 400px;
  }
}

@media screen and (max-width: 1150px) {
  .cardCarousel {
    min-width: 1600px;
    height: 350px;
  }
  .card {
    width: 220px;
    height: 350px;
  }
}
@media screen and (max-width: 1000px) {
  .cardCarousel {
    min-width: 1500px;
    height: 320px;
  }
  .card {
    width: 210px;
    height: 320px;
  }
}

@media screen and (max-width: 800px) {
  .cardCarousel {
    height: 300px;
  }
  .card {
    width: 200px;
    height: 300px;
  }
}
