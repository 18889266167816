// @import url('https://fonts.cdnfonts.com/css/geometria');

html {
  height: 100%;
  overflow: auto;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  font-family: 'Geometria', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  color: #fff;
  background-color: #2f2f2f;
  cursor: url('./assets/image/cursor.svg'), auto;

  // &.light {
  //   color: #2f2f2f;
  //   background-color: #e8f9fe;
  // }
}

a {
  text-decoration: none;
  color: inherit;

  cursor: url('./assets/image/pointer.svg'), auto;
}

button,
input {
  cursor: url('./assets/image/pointer.svg'), auto;
  font-family: 'Geometria', sans-serif;

  &::placeholder {
    font-family: 'Geometria', sans-serif;
  }
}

ul,
p,
h1,
h2 {
  margin: 0;
  padding: 0;
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8f9fe;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00bbe6;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00d0ff;
}

:root {
  --progress-width: 0;
  --buffered-width: 0;
}
.progress-bar::-webkit-slider-thumb {
  z-index: 4;
  position: relative;
}
.progress-bar::before {
  transform: scaleX(var(--progress-width));
  z-index: 3;
}
.progress-bar::after {
  transform: scaleX(var(--buffered-width));
  transform-origin: left;
  z-index: 2;
}