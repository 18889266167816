.container {
  overflow: hidden;
  padding-bottom: 30px;
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardCarouselInnerLeft {
  --card-width: 80%;
  --card-max-width: 200px;
  --card-height: 400px;
  --carousel-min-width: 600px;
  position: absolute;
  right: 0;
  margin: 0 auto;
  width: 15%;
  height: 102%;
  background-color: #2f2f2f;
}

.cardCarouselInnerRight {
  --card-width: 80%;
  --card-max-width: 200px;
  --card-height: 400px;
  --carousel-min-width: 600px;
  position: absolute;
  margin: 0 auto;
  width: 15%;
  height: 102%;
  background-color: #2f2f2f;
}

.light {
  background-color: #e8f9fe;
}

@media screen and (max-width: 1150px) {
  .container {
    margin-top: 20px;
    height: 350px;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    height: 320px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    margin-top: 20px;
    height: 300px;
  }
}

@media screen and (min-width: 2400px) {
  .container {
    margin-top: 100px;
    height: 600px;
  }
}

// @media screen and (max-width: 640px) {
//   .cardCarousel {
//     margin-left: calc((100vw - 600px - 40px) / 2);
//   }
// }
.imageContainer {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 60%;
  border-radius: 1em;
}

.title {
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  color: white;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 98, 98, 0.78);
  backdrop-filter: blur(2px);
  border-radius: 1em;
  transition: all 0.5s ease;

  &:hover {
    opacity: 1;
  }
}

.titleText {
  padding: 10px;
}
