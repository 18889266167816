.container {
  height: 25px;
  width: 50px;
  background-color: #2f2f2f;
  border: 1px solid white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
  transition: all 0.3s;

  &[data-darkmode='true'] {
    background-color: #e8f9fe;
    border: 1px solid #2f2f2f;
  }
}
.handle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
}
.icon {
  color: #52527a;
}

[data-darkmode='true'] {
  .handle {
    background-color: #2f2f2f;
  }
}

@media (max-width: 1000px) {
  .container {
    background: transparent;

    &[data-darkmode='true'] {
      background: #e8f9fe;
      border: 1px solid #2f2f2f;
    }
  }
}
