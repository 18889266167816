.navList {
  display: flex;
}

.link {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  margin-left: 30px;
  border: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #fff;
  }
  position: relative;
}

.mobileMenuWrap {
  position: fixed;
  top: 74px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  backdrop-filter: blur(12px);

  transition: 0.3s;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  li {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 20px;

    transition: 0.3s;

    svg {
      width: 17px !important;
      margin-left: 10px;
    }
  }
}

.toggle {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1320px) {
  .link {
    font-size: 14px;
  }
}
@media screen and (max-width: 1229px) {
  .link {
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .link {
    font-size: 10px;
  }
}
