.service {
  width: 70%;
  margin: 0 auto;
  min-height: 100%;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 2400px) {
    margin-top: 100px;
  }

  @media screen and (max-width: 1660px) {
    width: 80%;
  }

  @media screen and (max-width: 1480px) {
    width: 90%;
  }
  @media screen and (max-width: 980px) {
    width: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .container > img {
    justify-self: center;
    border-radius: 30px;
    width: 500px;
    aspect-ratio: 1/1;
    object-fit: cover;

    @media screen and (min-width: 2400px) {
      width: 600px;
    }

    @media screen and (max-width: 1480px) {
      width: 400px;
    }
    @media screen and (max-width: 1000px) {
      width: 300px;
    }
  }

  h2 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
    line-height: 1em;
    min-height: 3em;

    @media screen and (max-width: 1480px) {
      text-align: center;
      font-size: 30px;
    }
    @media screen and (max-width: 1000px) {
      margin: 20px 0;
      min-height: unset;
    }
  }

  .text {
    border-top: 4px solid rgba(127, 127, 127, 0.42);
    border-bottom: 4px solid rgba(127, 127, 127, 0.42);
    font-weight: 400;
    padding: 20px;

    @media screen and (max-width: 1000px) {
      margin: 0 auto;
      width: 500px;
    }
    @media screen and (max-width: 580px) {
      margin: 0 auto;
      width: 80%;
    }

    .textParagraph {
      width: 100%;
      column-count: 2;
      column-fill: auto;
      height: 340px;

      li {
        list-style-type: disc;
      }

      @media screen and (max-width: 1680px) {
        font-size: 14px;
      }

      @media screen and (max-width: 1250px) {
        height: 400px;
      }

      @media screen and (max-width: 1000px) {
        column-count: 1;
        height: auto;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .imageName {
    padding: 0 40px;
    margin: 20px 0;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    min-height: 2.5em;
    margin-bottom: 0;
  }

  .buttonPrice {
    margin-bottom: 20px;
    margin-top: 20px;
    justify-self: center;
    color: inherit;
    width: max-content;
    height: max-content;
    background-color: #00bbe6;
    padding: 10px 20px;
    border: none;
    font-weight: 700;
    font-size: 20px;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.55));

    &:hover {
      background-color: #00d0ff;
    }
  }
}

.skills {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  justify-content: center;

  &Mobile {
    margin-bottom: 50px;
  }

  @media (max-width: 1000px) {
    margin-top: 15px;
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & img,
  svg {
    width: 120px;
    height: 120px;

    @media (min-width: 2400px) {
      width: 160px;
      height: 160px;
    }

    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  }
  @media (max-width: 550px) {
    flex-direction: row;
    column-gap: 100px;
  }
  @media (min-width: 2400px) {
    img {
      width: 160px;
      height: 160px;
    }
  }
}

.description {
  margin-top: 15px;
  height: 50px;
  text-align: center;
  width: 130px;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;

  @media (max-width: 768px) {
    width: 80px;
  }
  @media (max-width: 550px) {
    text-align: left;
  }

  @media (min-width: 2400px) {
    width: 220px;
    font-size: 20px;
  }

  &::before {
    content: '';
    display: block;
    width: 65px;
    height: 1px;
    left: 32px;
    top: -10px;
    position: absolute;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.35);

    &body.light {
      background: black;
    }

    @media (min-width: 2400px) {
      width: 120px;
      left: 50px;
    }

    @media (max-width: 768px) {
      width: 40px;
      left: 20px;
    }
    @media (max-width: 550px) {
      content: '';
      display: block;
      width: 1px;
      height: 60px;
      left: -50px;
      position: absolute;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.35);
    }
  }
}

.lightDescription {
  &::before {
    background: #2f2f2f;
  }
}

.modal {
  position: absolute;
  z-index: 200;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.content {
  border-radius: 30px;
  color: #fff;
  margin: 0 auto;
  padding: 20px 40px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #2f2f2f;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
}

.closeModal {
  background: none;
  border: none;
  align-self: flex-end;
  color: #fff;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;

  input {
    padding: 20px;
    background: none;
    border: 1px solid #fff;
    margin-bottom: 10px;
    color: #fff;

    &::placeholder {
      color: #fff;
    }
  }

  .inputError {
    border: 1px solid red;
  }

  span {
    text-decoration: underline;
  }
}

.title {
  align-self: center;
  font-size: 24px;
}

.subTitle {
  text-align: center;
  align-self: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.send {
  margin-top: 20px;
  align-self: center;
  color: inherit;
  width: max-content;
  height: max-content;
  background-color: #00bbe6;
  padding: 10px 20px;
  border: none;
  font-weight: 700;
  font-size: 20px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.55));

  &:hover {
    background-color: #00d0ff;
  }

  &:disabled {
    background-color: gray;
    &:hover {
      cursor: unset;
      background-color: gray;
    }
  }
}

.backButton {
  margin-left: auto;
  align-self: center;
  text-transform: uppercase;
  height: max-content;
  border: none;
  background-color: #05b7e0;
  padding: 5px 10px;
  color: inherit;
}

.textConfirm {
  padding: 10px;
  padding-bottom: 20px;
  height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ul {
    padding-left: 20px;
    list-style-type: disc;
    li {
      margin-bottom: 10px;
    }
  }
}

.success {
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 24px;
}
