.footer {
  width: 100%;
  margin-top: 50px;
  text-align: center;
}

.textFooter {
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
}
