.canvas {
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;  
}

.canvas::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, rgba(228, 23, 23, 0), rgba(47, 58, 83, 1));
}


.canvas1 {
  @extend .canvas;
  background-image: url('./astafiev/astafiev_bg1.png');
}

.canvas2 {
  @extend .canvas;
  background-image: url('./astafiev/astafiev_bg2.png');
}

.canvas3 {
  @extend .canvas;
  background-image: url('./astafiev/astafiev_bg3.png');
}

.canvas4 {
  @extend .canvas;
  background-image: url('./astafiev/astafiev_bg4.png');
}

.audioPlayer {
  margin-top: 20px;
  margin-bottom: 20px;

    .audioPlayerProgress{
      margin-bottom: 20px;
      input[type="range"] {
        width: 100%;
        -webkit-appearance: none;
        background-color: transparent;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        background-color: #ddd;
        border-radius: 2px;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 30px;
        height: 30px;
        background-color: white;
        border-radius: 50%;
        margin-top: -12px;
      }
//  ------------------------------------------------------------------------
      // input[type="range"]::-moz-range-track {
      //   width: 100%;
      //   height: 4px;
      //   background-color: #ddd;
      //   border-radius: 2px;
      // }

      // input[type="range"]::-moz-range-thumb {
      //   width: 16px;
      //   height: 16px;
      //   background-color: #666;
      //   border-radius: 50%;
      // }
      
      // input[type="range"]::-ms-track {
      //   width: 100%;
      //   height: 4px;
      //   background-color: black;
      //   border-radius: 2px;
      // }
      
      // input[type="range"]::-ms-thumb {
      //   width: 16px;
      //   height: 16px;
      //   background-color: #666;
      //   border-radius: 50%;
      // }
//  ------------------------------------------------------------------------

      .timing {
        display: flex;
        justify-content: space-between;
      }
    }
    .audioPlayerButtons{
      
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      text-align: center;
      .rewind{
        img {
          width: 70%;
          height: auto;
          @media (width > 550px) {
            width: 40%;
            height: auto;
          }
        }
      }

      .playPause{
        img {
          width: 90%;
          height: auto;
          @media (width > 550px) {
            width: 50%;
            height: auto;
          }
        }
      }
      
    }
}


// ---------------------------------------------------------------


.card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 40px;
  color: black;
  background-color: rgba(255, 255, 255, 0.5);

  .description {

    text-align: center;
    color: #2D2D2B;
    font-weight: 700;
    line-height: 1.5;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    opacity: 1;
  }
  
  .note {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }


  .header {
    opacity: 1;
  }

  
}
// -------Desktop--------------------------------------------------------
.cardDesktop {
  @extend .card;
  position: absolute;
  
  padding: 40px;

  .description {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }



}


.desktopTicket {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  left: -1%;
  opacity: 0.9;
}

// -------MOBILE--------------------------------------------------------
.cardMobile {
  @extend .card;
  position: relative;
  left: 43%;
  margin: 22px;
  padding: 15px;
  max-height: 100%;
// -----------------
  .header {
    img {
      width: 100%;
      height: auto;
    }
  }
// ------------------
  .note {
    img{
      width: 100%;
      height: auto;
    }
  }
// ------------------
  .bookCover{
    padding: 0;
  }
// ------------------
  .gridContainer {
    display: grid;
    grid-auto-columns: auto;
    padding: 10px;
  }
// ------------------
  .bookGridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
      
    img {
      width: 100%;
      height: auto;
    }

    .description {
      p {
        text-align: start;
        overflow: auto;
        font-size: small;
        max-height:150px;
        
      }
      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    
  }
// ------------------


}

.mobileLayout {
  display: grid;
  grid-auto-columns: auto;
  
  .ticketGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 15px;
    z-index: 100;
    opacity: 1;
    img {
      width: 100%;
      height: auto;
    }
  }
}


// ---------------------------------------------------------------------------------------------------------------------------------------------------

