.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 100vw;

  background-color: rgba($color: #000000, $alpha: 0.8);

  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: auto;
  }

  .next,
  .prev {
    position: absolute;
    top: 50%;
    cursor: url('../../../assets/image/pointer.svg'), auto;
    @media (max-width: 768px) {
      width: 50px;
      top: 90%;
    }
  }

  .prev {
    left: 5vw;
  }
  .next {
    right: 5vw;
  }

  .close {
    position: absolute;
    top: 30px;
    right: 30px;

    cursor: url('../../../assets/image/pointer.svg'), auto;
  }
}
