.item {
  width: 100%;
  height: auto;

  margin: 0 1px;

  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

.subItem {
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}