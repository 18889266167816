.services {
  min-height: 100%;
  text-transform: uppercase;
  margin-top: 100px;
  margin-bottom: 40px;

  @media (max-width: 1150px) {
    margin-top: 50px;
  }
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 550px) {
    margin-top: 0px;
  }

  .text {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 950px) {
      grid-template-columns: 1fr;
    }

    .serviceName {
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 300;
      display: flex;
      @media (min-width: 1920px) {
        font-size: 25px;
      }
      @media (max-width: 1150px) {
        font-size: 14px;
      }

      @media (max-width: 950px) {
        font-size: 16px;
      }

      @media (max-width: 550px) {
        margin-bottom: 10px;
      }

      img {
        width: 30px;
        height: 32px;
        margin-right: 20px;

        @media (max-width: 1150px) {
          height: 20px;
        }
      }
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  @media (max-width: 1150px) {
    margin: 0px;
  }
}

.title {
  margin: 0 auto;
  padding: 5px 10px;
  max-width: max-content;
  background-color: #00bbe6;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  @media (min-width: 1920px) {
    font-size: 35px;
  }

  @media (max-width: 1150px) {
    font-size: 20px;
  }
  @media (max-width: 950px) {
    font-size: 20px;
  }
}

.textService {
  margin-bottom: 40px;
  @media (max-width: 550px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.serviceName {
}
