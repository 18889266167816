.portfolio {
  h2 {
    margin-bottom: 20px;
    @media (min-width: 1920px) {
      font-size: 16px;
    }
    @media (min-width: 2400px) {
      font-size: 25px;
    }
    @media (max-width: 768px) {
      font-size: 10px;
    }
    @media (max-width: 550px) {
      font-weight: 500;
      font-size: 9px;
    }
  }
  p {
    @media (min-width: 1920px) {
      font-size: 20px;
    }
    @media (min-width: 2400px) {
      font-size: 32px;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
}

.title {
  text-align: center;
  line-height: 2;
  font-weight: 400;
  letter-spacing: 1.95px;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.mobilePortfiol {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  width: 100%;

  a {
    overflow: hidden;
    margin-bottom: 30px;

    @media (max-width: 1000px) {
      width: 48%;
      min-width: 45%;
    }
    @media (max-width: 768px) {
      width: 100%;
      min-width: 45%;
      margin-bottom: 20px;
    }

    div {
      width: 100%;
      height: 100%;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.footer {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .textFooter {
    font-size: 12px;
    margin: 0 auto;
    position: relative;
    .firstStr {
      text-transform: uppercase;
      letter-spacing: .1rem;
      font-weight: 500;
    }
    .secondStr{
      font-size: 14px;
      opacity: .8;
    }
  }

  .toggle {
    margin-left: -62px;
    align-self: center;
  }

  @media (min-width: 2400px) {
    .textFooter {
      font-size: 26px;
    }
  }

  @media (max-width: 1000px) {
    justify-content: center;
    & .toggle {
      display: none;
    }
  }
}
