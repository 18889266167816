.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.tel {
  font-weight: 500;
  letter-spacing: 0.263px;
  font-size: 17px;
  text-decoration: underline;
}

.link {
  & img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 1320px) {
  .tel {
    font-size: 14px;
  }
  .container {
    gap: 10px;
  }
}

@media screen and (max-width: 1229px) {
  .tel {
    font-size: 12px;
  }
  .container {
    gap: 5px;
  }
  .link {
    & img {
      width: 20px;
      height: 20px;
    }
  }
}
@media screen and (max-width: 1100px) {
  .tel {
    font-size: 10px;
  }
}
