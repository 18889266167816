.about {
  position: relative;
  margin-bottom: 160px;
  @media screen and (min-width: 2400px) {
    margin-left: 150px;
  }
  .title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 35px;
    font-weight: 500;
    font-size: 46px;
    width: max-content;
    border-bottom: 2px solid black;

    &White {
      border-bottom: 2px solid white;
    }

    @media screen and (max-width: 800px) {
      font-size: 20px;
      margin-left: 0px;
    }
    @media screen and (min-width: 2400px) {
      margin-top: 60px;
    }
  }
}

.loader {
  width: 100%;
  height: 500px;
}

.content {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 2fr 1fr;
  font-weight: 500;

  @media screen and (min-width: 2400px) {
    grid-template-columns: 1fr 1fr;
    font-size: 20px;
  }

  @media screen and (max-width: 1850px) {
    justify-items: center;
  }
  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
    justify-items: start;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  .firstIteoImage {
    width: 700px;
    filter: drop-shadow(6px 7px 4px rgba(0, 0, 0, 0.35));

    @media screen and (max-width: 1850px) {
      width: 500px;
    }
    @media screen and (max-width: 1350px) {
      width: 300px;
    }
  }

  .secondIteoImage {
    width: 500px;
    border-radius: 30px;
    transform: rotate(-5deg);
    filter: drop-shadow(6px 7px 4px rgba(0, 0, 0, 0.35));

    @media screen and (max-width: 1850px) {
      width: 400px;
    }

    @media screen and (max-width: 1350px) {
      width: 300px;
    }
  }

  .firstAbout {
    position: relative;
    margin-top: 20px;
  }

  .firstCount {
    top: -40px;
    left: -50px;
    transform: rotate(10deg);
    position: absolute;
    color: transparent;
    font-size: 80px;
    font-weight: 500;
    -webkit-text-stroke: 2px #2e2e2e;
  }

  .secondCount {
    top: 425px;
    left: -287px;
    transform: rotate(-20deg);
    position: absolute;
    color: transparent;
    font-size: 80px;
    font-weight: 500;
    -webkit-text-stroke: 2px white;

    @media screen and (max-width: 1850px) {
      top: 325px;
      left: -287px;
    }

    @media screen and (max-width: 1350px) {
      top: 225px;
      left: -227px;
    }
    @media screen and (max-width: 800px) {
      top: 225px;
      left: -53px;
    }
  }

  p {
    width: 50%;
    margin-bottom: 20px;

    @media screen and (max-width: 1850px) {
      width: 70%;
    }
    @media screen and (max-width: 1350px) {
      width: 100%;
    }
  }

  .thirdIteoImage {
    position: relative;
    right: -100px;
    width: 500px;
    border-radius: 30px;
    transform: rotate(10deg);
    filter: drop-shadow(6px 7px 4px rgba(0, 0, 0, 0.35));

    @media screen and (max-width: 1850px) {
      width: 400px;
    }

    @media screen and (max-width: 1500px) {
      right: -50px;
    }
    @media screen and (max-width: 1350px) {
      width: 300px;
    }
    @media screen and (max-width: 1000px) {
      right: 50px;
    }

    @media screen and (max-width: 800px) {
      top: 30px;
    }
  }

  .secondAbout {
    position: relative;
    margin-top: 50px;
  }

  .thirdCount {
    top: 150px;
    left: 180px;
    transform: rotate(30deg);
    position: absolute;
    color: transparent;
    font-size: 80px;
    font-weight: 500;
    -webkit-text-stroke: 2px #2e2e2e;
  }

  .arrow {
    position: relative;

    @media screen and (min-width: 2400px) {
      top: -20px;
    }

    img {
      position: absolute;
      left: -200px;
      width: 200px;

      @media screen and (max-width: 1850px) {
        top: 20px;
      }
      @media screen and (max-width: 1000px) {
        top: 0px;
      }

      @media screen and (max-width: 800px) {
        transform: rotate(90deg);
        top: -119px;
        left: -121px;
      }
    }

    p {
      position: absolute;
      top: 50px;
      left: -150px;

      @media screen and (max-width: 1850px) {
        top: 70px;
      }
      @media screen and (max-width: 1000px) {
        top: 50px;
      }
      @media screen and (max-width: 1000px) {
        top: 20px;
        left: -30px;
      }
    }
  }
}

.background {
  position: absolute;
  top: 80px;
  left: 200px;
  width: 950px;
  height: 1000px;
  border-radius: 30px;
  transform: skew(-4deg);
  background-color: rgba(0, 187, 230, 0.74);

  @media screen and (min-width: 2400px) {
    width: 1150px;
    height: 900px;
  }

  @media screen and (max-width: 1850px) {
    left: 350px;
    width: 850px;
    height: 850px;
  }
  @media screen and (max-width: 1700px) {
    left: 250px;
  }
  @media screen and (max-width: 1560px) {
    left: 200px;
  }
  @media screen and (max-width: 1500px) {
    left: 150px;
  }

  @media screen and (max-width: 1350px) {
    left: 90px;
    width: 850px;
    height: 700px;
  }
  @media screen and (max-width: 1300px) {
    left: 90px;
    width: 800px;
  }
  @media screen and (max-width: 1000px) {
    left: 0px;
    width: 650px;
  }
  @media screen and (max-width: 900px) {
    left: -80px;
    width: 700px;
  }
  @media screen and (max-width: 800px) {
    width: 670px;
    top: 130px;
    height: 970px;
  }
  @media screen and (max-width: 600px) {
    width: 370px;
    top: 130px;
    height: 1050px;
  }
}

.projects {
  background-color: #2e2e2e;
  color: #fff;
  padding: 40px 20px;

  .tagline {
    font-weight: 400;
    margin: 0 auto;
    width: 50%;
    font-size: 26px;
    margin-bottom: 140px;

    @media screen and (max-width: 1000px) {
      width: 80%;
      margin-bottom: 50px;
      font-size: 16px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    width: max-content;
    margin: 0 auto;
    border-bottom: 2px solid #fff;
    @media screen and (max-width: 1000px) {
      font-size: 26px;
    }
  }

  .categories {
    margin-top: 20px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    gap: 30px;
    font-size: 20px;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      gap: 15px;
    }

    button {
      font-size: 20px;
      background: none;
      border: none;
      color: inherit;
      text-transform: uppercase;

      @media screen and (max-width: 1000px) {
        font-size: 14px;
      }
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }

      &.active {
        text-decoration: underline;
        filter: drop-shadow(0px 0px 2px #fff);
      }
    }
  }

  .projects {
    margin: 0 auto;
    width: 60%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin-bottom: 300px;

    @media screen and (max-width: 1400px) {
      width: 80%;
    }
    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    .project {
      cursor: url('../../../assets/image/pointer.svg'), auto;

      img {
        border-radius: 20px;
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
      }
      p {
        position: relative;
        @media screen and (min-width: 2400px) {
          margin-top: 5px;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: -18px;
          width: 100%;
          height: 14px;
          background-color: #2e2e2e;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -20px;
          width: 100%;
          height: 2px;
          background-color: #fff;
          filter: drop-shadow(0px 0px 2px #fff);
          box-shadow: 0px 0px 2px #fff;
        }
      }
    }
  }
}
