.wrapper {
  margin-top: 20px;
  margin-bottom: 50px;
}

.imageContainer {
  position: relative;
  height: 100%;

  img {
    object-fit: cover;
  }
}

.title {
  position: absolute;
  top: 0;
  opacity: 0;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
  background-color: #05b7e0;
  transition: all 0.5s ease;

  &:hover {
    opacity: 1;
  }
}

.titleText {
  font-size: 22px;
  font-weight: 500;
  text-decoration: underline;
  padding: 10%;

  @media (min-width: 2400px) {
    font-size: 30px;
  }

  @media (max-width: 1400px) {
    font-size: 18px;
  }
}

.portfolio {
  margin-bottom: 20px;
  display: grid;
  gap: 20px;
  grid-template-areas:
    'A A A A   A A B B   B C C C'
    'A A A A   A A D D   D E E E';

  @media (max-width: 1000px) {
    grid-template-areas:
      'A A'
      'B C'
      'D E';
  }
  @media (max-width: 550px) {
    grid-template-areas:
      'A'
      'B'
      'C'
      'D'
      'E';
  }

  div {
    div {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  :nth-child(1) {
    grid-area: A;
  }
  :nth-child(2) {
    grid-area: B;
  }
  :nth-child(3) {
    grid-area: C;
  }
  :nth-child(4) {
    grid-area: D;
  }
  :nth-child(5) {
    grid-area: E;
  }
}

.portfolioSecond {
  margin-bottom: 20px;
  display: grid;
  gap: 20px;
  grid-template-areas:
    'A B B C C'
    'D D E E F';

  @media (max-width: 1000px) {
    grid-template-areas:
      'A B'
      'C D'
      'E F';
  }
  @media (max-width: 550px) {
    grid-template-areas:
      'A'
      'B'
      'C'
      'D'
      'E'
      'F';
  }

  div {
    img {
      max-height: 400px;
      width: 100%;
      height: 100%;
      background-size: contain;
      object-fit: cover;
    }
  }
  :nth-child(1) {
    grid-area: A;
  }
  :nth-child(2) {
    grid-area: B;
  }
  :nth-child(3) {
    grid-area: C;
  }
  :nth-child(4) {
    grid-area: D;
  }
  :nth-child(5) {
    grid-area: E;
  }
  :nth-child(6) {
    grid-area: F;
  }
}
